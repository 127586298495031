
import { defineComponent } from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import RowCol from "@/components/base/common/RowCol.vue";
import { LoadPanel } from "@/core/composite/composite";
import PolicyLink from "@/views/policy/PolicyLink.vue";

export default defineComponent({
  name: "PolicyViewCard",
  components: {PolicyLink, RowCol, PolicyStatus, QuickAction},
  props: {
    view: {required: true, type: Object}
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }
})
