import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap py-3 fs-6 fw-bold" }
const _hoisted_2 = {
  key: 0,
  class: "flex-grow-1 text-gray-400"
}
const _hoisted_3 = { class: "flex text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!

  return (_ctx.value !== null || _ctx.showIfNull)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.colSize)
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label) + ": ", 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.type === 'LINK')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _ctx.params?.router,
                  target: _ctx.params?.target
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.value), 1)
                  ]),
                  _: 1
                }, 8, ["to", "target"]))
              : (_ctx.type ==='DATE')
                ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                    key: 1,
                    date: _ctx.value
                  }, null, 8, ["date"]))
                : (_ctx.type ==='CUSTOM')
                  ? _renderSlot(_ctx.$slots, "custom", { key: 2 })
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createTextVNode(_toDisplayString(_ctx.value), 1)
                    ], 64))
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}