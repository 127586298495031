
import {defineComponent} from 'vue'
import RowCol from "@/components/base/common/RowCol.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "BHSIViewCard",
  components: {RowCol},
  props: {
    view: {type: Object, required: true}
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }

})
