
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "PolicyRemoteLink",
  props: {
    policy: {type: Object, required: true},
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
