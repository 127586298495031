
import { defineComponent } from "vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "RowCol",
  components: {
    DateTimeFormat
  },
  props: {
    size: {type: Number, required: false, default: 6},
    label: {type: String, required: true},
    value: {required: true},
    type: {type: String, required: false},
    showIfNull: {type: Boolean, required: false, default: false},
    //
    params: {}
  },
  computed: {
    colSize() {
      return 'col-' + this.size
    }
  }
})
